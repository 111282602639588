<template>
    <div>
        <el-button class="sb-btn" v-if="orderDirection === 1" @click="doClick(-1)">
            <el-image class="sb-image" :src="require('@/assets/image/sort-up.png')"></el-image>
            <div class="sb-text">升序</div>
        </el-button>
        <el-button class="sb-btn" v-if="orderDirection === -1" @click="doClick(1)">
            <el-image class="sb-image" :src="require('@/assets/image/sort-down.png')"></el-image>
            <div class="sb-text">降序</div>
        </el-button>
    </div>
</template>

<script>
export default {
    name: "nh-sort-boolean",
    model: {
        prop: 'orderDirection',
        event: 'change'
    },
    props:{
        orderDirection: Number
    },
    data() {
        return {

        }
    },
    methods:{
        doClick(orderDirection) {
            this.$emit('change', orderDirection)
        }
    }
}
</script>

<style scoped>
.sb-btn {
    background: transparent;
    border: unset;
}
.sb-image {
    height: 14px;
}
.sb-text {
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: #5369E2;
    letter-spacing: 0;
}
</style>