<template>
    <div>
        <nh-title></nh-title>
        <div style="display: flex">
            <div>
                <nh-menu active-selected="PatientList"></nh-menu>
            </div>
            <div class="list-container">
                <div class="search-bar-wrapper">
                    <div class="search-sort-wrapper">
                        <div style="margin-left: 20px">
                            <nh-sort-button v-model="searchParams.orderProperty" @change="doSearch"></nh-sort-button>
                        </div>
                        <div>
                            <nh-sort-boolean v-model="searchParams.orderDirection" @change="doSearch"></nh-sort-boolean>
                        </div>
                    </div>
                    <div style="display: flex">
                        <div class="search-input-wrapper">
                            <div class="search-bar">
                                <el-input class="search-input" v-model="searchParams.searchKey"
                                          @keyup.enter.native="doSearch" placeholder="查找" clearable/>
                            </div>
                            <div class="search-button">
                                <el-image
                                        src="https://yitian-cms.oss-cn-hangzhou.aliyuncs.com/img/2ec27f9034285ffd9cf33f2a19dfb4af.png"
                                        mode="aspectFit" @click="doSearch"></el-image>
                            </div>
                        </div>
                        <div class="button-wrapper">
                            <div class="add-button" v-if="!isAdmin">
                                <img src="https://yitian-cms.oss-cn-hangzhou.aliyuncs.com/img/de5107bbc0efbd55bd7d5adc68714391.png"
                                     @click="createClick"/>
                            </div>
                            <div class="add-button" v-if="isAdmin">
                                <img src="https://yitian-cms.oss-cn-hangzhou.aliyuncs.com/img/c6bd0083373085ba179b4be5f373d12b.png"
                                     @click="exportClick"/>
                            </div>
                        </div>
                    </div>
                </div>
                <el-table class="list-table-container"
                          :data="patientList"
                          @cell-click="doCellClick"
                          @sort-change="doSortChange"
                          stripe>
                    <el-table-column v-if="isAdmin"
                                     label="专员">
                        <template slot="header" slot-scope="scope">
                            <el-select :aa="scope" v-model="searchParams.filterDoctorId" clearable placeholder="专员"
                                       @change="searchChange">
                                <el-option v-for="item in nurseList" :key="item.id" :label="item.name"
                                           :value="item.doctorId"></el-option>
                            </el-select>
                        </template>
                        <template slot-scope="scope">
                            {{ getNurse(scope.row) }}
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="编号"
                            prop="number">
<!--                    <el-table-column-->
<!--                            label="编号"-->
<!--                            prop="number"-->
<!--                            sortable="custom">-->
                        <template slot-scope="scope">
                            {{ scope.row.number2 ? scope.row.number2 : scope.row.number }}
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="xh"
                            label="序号">
                        <template slot-scope="scope">
                            {{ getColumnValue(scope.row.rzSurvey, 'xh') }}
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="姓名">
                        <template slot-scope="scope">
                            {{ getColumnValue(scope.row.rzSurvey, 'name') }}
                        </template>
                    </el-table-column>
                    <el-table-column
                            width="120"
                            label="医院">
                        <template slot="header" slot-scope="scope">
                            <SelectDatasourcePicker :aa="scope" :sourceKey="'hospital'" v-model="searchParams.hospital"
                                                    placeholder="医院" @change="searchChange"></SelectDatasourcePicker>
                        </template>
                        <template slot-scope="scope">
                            {{ getSubHospital(getColumnValue(scope.row.rzSurvey, 'hospital')) }}
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="科室">
                        <template slot-scope="scope">
                            {{ getColumnValue(scope.row.rzSurvey, 'keshi') }}
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="处方医生">
                        <template slot-scope="scope">
                            {{ getColumnValue(scope.row.rzSurvey, 'cfys') }}
                        </template>
                    </el-table-column>
                    <el-table-column
                            width="200"
                            align="center"
                            label="入组日期">
                        <template slot="header" slot-scope="scope">
                            <el-date-picker :aa="scope"
                                            v-model="searchParams.rzrq"
                                            type="daterange"
                                            class="rzrq-header"
                                            @change="searchChange"
                                            value-format="yyyy-MM-dd"
                                            range-separator="-"
                                            start-placeholder="入组开始日期"
                                            end-placeholder="入组结束日期">
                            </el-date-picker>
                        </template>
                        <template slot-scope="scope">
                            {{ getColumnValue(scope.row.rzSurvey, 'rzrq') }}
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="适应症">
                        <template slot="header" slot-scope="scope">
                            <el-select :aa="scope" v-model="searchParams.syz" clearable placeholder="适应症"
                                       @change="searchChange" class="select-header">
                                <el-option v-for="item in jcjbzdList" :key="item.id" :label="item.name"
                                           :value="item.id"></el-option>
                            </el-select>
                        </template>
                        <template slot-scope="scope">
                            {{ getColumnValue(scope.row.rzSurvey, 'syz') }}
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="合并症">
                        <template slot-scope="scope">
                            {{ getColumnValue(scope.row.rzSurvey, 'jcjbzd') }}
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="用药方案">
                        <template slot="header" slot-scope="scope">
                            <el-select :aa="scope" v-model="searchParams.yyfa" clearable placeholder="用药方案"
                                       @change="searchChange" class="select-header">
                                <el-option v-for="item in yyfaList" :key="item.id" :label="item.name"
                                           :value="item.id"></el-option>
                            </el-select>
                        </template>
                        <template slot-scope="scope">
                            {{ getColumnValue(scope.row.rzSurvey, 'yyfa') }}
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="用药频率">
                        <template slot="header" slot-scope="scope">
                            <el-select :aa="scope" v-model="searchParams.yypc" clearable placeholder="用药频率"
                                       @change="searchChange" class="select-header">
                                <el-option v-for="item in yypcList" :key="item.id" :label="item.name"
                                           :value="item.id"></el-option>
                            </el-select>
                        </template>
                        <template slot-scope="scope">
                            {{ getColumnValue(scope.row.rzSurvey, 'yypc') }}
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="rzfs"
                            align="center"
                            label="入组方式">
                        <template slot="header" slot-scope="scope">
                            <el-select :aa="scope" v-model="searchParams.rzfs" clearable placeholder="入组方式"
                                       @change="searchChange" class="select-header">
                                <el-option v-for="item in rzfsList" :key="item" :label="item"
                                           :value="item"></el-option>
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="入组状态">
                        <template slot="header" slot-scope="scope">
                            <el-select :aa="scope" v-model="searchParams.rzzt" clearable placeholder="入组状态"
                                       @change="searchChange" class="select-header">
                                <el-option v-for="item in rzztList" :key="item.id" :label="item.name"
                                           :value="item.id"></el-option>
                            </el-select>
                        </template>
                        <template slot-scope="scope">
                            {{ getColumnValue(scope.row.rzSurvey, 'rzfs') }}
                        </template>
                    </el-table-column>
<!--                    <el-table-column-->
<!--                            label="首次注射">-->
<!--                        <template slot-scope="scope">-->
<!--                            {{ getColumnValue(scope.row.rzSurvey, 'sczs') }}-->
<!--                        </template>-->
<!--                    </el-table-column>-->
<!--                    <el-table-column-->
<!--                            label="末次注射">-->
<!--                        <template slot-scope="scope">-->
<!--                            {{ getColumnValue(scope.row.sfSurvey, 'mczs', true) }}-->
<!--                        </template>-->
<!--                    </el-table-column>-->
<!--                    <el-table-column-->
<!--                            label="是否停药">-->
<!--                        <template slot-scope="scope">-->
<!--                            {{ getColumnValue(scope.row.sfSurvey, 'ty') }}-->
<!--                        </template>-->
<!--                    </el-table-column>-->
                    <el-table-column
                            label="患者状态">
                        <template slot="header" slot-scope="scope">
                            <el-select :aa="scope" v-model="searchParams.hzzt" clearable placeholder="患者状态"
                                       @change="searchChange" class="select-header">
                                <el-option v-for="item in hzztList" :key="item.id" :label="item.name"
                                           :value="item.id"></el-option>
                            </el-select>
                        </template>
                        <template slot-scope="scope">
                            <el-tooltip v-if="scope.row.hzzt === '5'" effect="dark" :content="scope.row.hzztError" placement="top-start">
                                <div>{{ getHzzt(scope.row) }}</div>
                            </el-tooltip>
                            <div v-else>{{ getHzzt(scope.row) }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="dot">
                        <template slot="header" slot-scope="scope">
                            <el-select :aa="scope" v-model="searchParams.dot" clearable placeholder="dot"
                                       @change="searchChange" class="select-header">
                                <el-option v-for="item in dotList" :key="item" :label="item"
                                           :value="item"></el-option>
                            </el-select>
                        </template>
                        <template slot-scope="scope">
                            {{ scope.row.dot }}
                        </template>
                    </el-table-column>
                </el-table>
                <div style="text-align: right;">
                    <el-pagination
                            layout="total,prev,pager,next"
                            :total="total"
                            :page-size="$urlConstant.pageSize"
                            :current-page="currentPage"
                            @current-change="fetchData">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NhTitle from "@/components/patient/components/nh-title";
import NhMenu from "@/components/patient/components/nh-menu";
import SelectDatasourcePicker from '@/components/survey/components/properties/singleSelect/SelectDatasourcePicker'
import StringUtil from "@/common/utils/StringUtil";
import surveyUtil from "@/common/surveyUtil";
import {
    // mapActions,
    mapState,
    mapMutations,
    // mapGetters
} from 'vuex'
import NhSortButton from "@/components/patient/components/nh-sort-button";
import NhSortBoolean from "@/components/patient/components/nh-sort-boolean";
import rzConstant from "@/common/rzConstant";

let fromName = undefined
export default {
    name: "PatientList",
    components: {NhSortBoolean, NhSortButton, NhMenu, NhTitle, SelectDatasourcePicker},
    data() {
        return {
            patientList: [],
            currentPage: 1,
            total: 0,
            totalPages: 0,
            nurseList: [],
            searchParams: {
                status: 1,
                orderDirection: -1,
                orderProperty: 'surveyModifiedTime'
            },
            rzfsList: ['线下', '远程'],
            rzztList: [],
            yypcList: [],
            yyfaList: [],
            jcjbzdList: [],
            hzztList: rzConstant.hzzt,
            dotList:[]
        }
    },
    computed: {
        ...mapState('patient', [
            'searchQuery'
        ]),
        isAdmin() {
            return this.$sess.getCurrentDoctor().admin
        }
    },
    // 保留状态的情况:文章编辑
    beforeRouteEnter(to, from, next) {
        fromName = from.name
        next();
    },
    watch: {},
    created() {
        if (fromName !== "Patient" && fromName !== "Survey") {
            this.cleanSearchQuery()
        }
        this.searchParams = JSON.parse(JSON.stringify(this.searchQuery))
        this.currentPage = this.searchParams.currentPage
        this.fetchData(this.currentPage)
        this.fetchNurses()
        this.fetchSurveyInfos()
        this.fetchDots()
    },
    methods: {
        ...mapMutations('patient', [
            'storeSearchQuery',
            'cleanSearchQuery'
        ]),
        fetchData(index) {
            this.searchParams.currentPage = index
            this.storeSearchQuery(this.searchParams)
            this.$httpUtil.post(StringUtil.stringInject(this.$urlConstant.nuohua.patientList, [
                    index - 1,
                    this.$urlConstant.pageSize
                ]),
                this.searchParams,
                (responseData) => {
                    this.patientList = responseData.data.content
                    this.total = responseData.data.totalElements
                    this.totalPages = responseData.data.totalPages
                    this.currentPage = index;
                },
                this
            )
        },
        fetchNurses() {
            if (this.isAdmin) {
                this.$httpUtil.get(this.$urlConstant.nuohua.nurseList, (res) => {
                    this.nurseList = res.data
                    this.nurseList.push({doctorId: "###", name: "未分配"})
                }, this)
            }
        },
        fetchSurveyInfos() {
            this.$httpUtil.get(this.$urlConstant.nuohua.defaultSurveyList, (res) => {
                const rzSurvey = res.data.rz
                // const sfSurvey = res.data.sf
                this.yypcList = surveyUtil.findPropertyById(rzSurvey, 'yypc').options
                this.yyfaList = surveyUtil.findPropertyById(rzSurvey, 'yyfa').options
                this.jcjbzdList = surveyUtil.findPropertyById(rzSurvey, 'syz').options.sort((a, b)=>{
                    return a > b ? 1 : -1
                })
                this.rzztList = surveyUtil.findPropertyById(rzSurvey, 'rzfs').options
            }, this)
        },
        fetchDots() {
            this.$httpUtil.get(this.$urlConstant.nuohua.dotList, (res) => {
                this.dotList = res.data
                this.dotList.sort(function(a, b) {
                    return a - b;
                });
            }, this)
        },
        createClick() {
            this.$httpUtil.get(this.$urlConstant.patient.patientConfig,
                res => {
                    const config = res.data
                    if (config.createPatientSurveyId) {
                        this.cleanSearchQuery()
                        this.$router.push({
                            name: 'Survey',
                            query: {survey: config.createPatientSurveyId}
                        })
                    } else {
                        this.$message.error('后台配置错误,未绑定surveyId')
                    }
                }, this)
        },
        doRowClick(patient) {
            this.$router.push({
                name: 'Patient',
                query: {id: patient.patientId}
            })
        },
        showXhPopup(patient) {
            this.$prompt('请输入序号', '', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                confirmButtonClass: 'xh-confirm-button',
                cancelButtonClass: 'xh-cancel-button',
                closeOnClickModal: false,
                inputValue: surveyUtil.findPropertyValueById(patient.rzSurvey, 'xh'),
                beforeClose: (action, instance, done) => {
                    console.log(action)
                    console.log(instance.inputValue)
                    const value = instance.inputValue
                    if (action === 'confirm') {
                        const param = {
                            patientId: patient.patientId,
                            xh: value
                        }
                        this.$httpUtil.post(this.$urlConstant.nuohua.updateXh,param,()=>{
                            let xhProperty = surveyUtil.findPropertyById(patient.rzSurvey, 'xh')
                            if (!xhProperty) {
                                xhProperty = {id:'xh', type: 3,propertyData:{propertyId: 'xh',content:value}}
                                patient.rzSurvey.properties.push(xhProperty)
                            }else {
                                if (!xhProperty.propertyData) {
                                    xhProperty.propertyData = {propertyId: 'xh',content:value}
                                }else {
                                    xhProperty.propertyData.content = value
                                }
                            }
                            this.$message({
                                type: 'success',
                                message: '修改成功:' + value
                            });
                            done()
                        }, this)
                    }else
                        done()
                }
            }).then(({ value }) => {
                console.log(value)
            }).catch(() => {

            });
        },
        doCellClick(row, column) {
            if (column.property === 'xh') {
                this.showXhPopup(row)
            }else {
                this.doRowClick(row)
            }
        },
        getColumnValue(survey, propertyId,notReturnHidden) {
            return surveyUtil.findPropertyValueById(survey, propertyId,notReturnHidden)
        },
        getSubHospital(hospital) {
            if (hospital) {
                const h = hospital.split(',')
                if (h.length > 2)
                    return h[2]
            }
            return hospital
        },
        getNurse(patient) {
            if (patient.doctorInfoList) {
                for (const nurseInfo of patient.doctorInfoList) {
                    const nurse = this.nurseList.find(nurse => nurse.doctorId === nurseInfo.doctorId)
                    if (nurse)
                        return nurse.name
                }
            }
            return ''
        },
        searchChange() {
            this.fetchData(1)
        },
        exportClick() {
            // this.$message.info('敬请期待')
            this.$router.push({name: 'ReportList'})
        },
        doSearch() {
            this.fetchData(1)
        },
        doSortChange(param) {
            console.log(param)
            if ('descending' === param.order) {
                this.searchParams.orderByNumber2 = -1
            }else if ('ascending' === param.order) {
                this.searchParams.orderByNumber2 = 1
            }else {
                this.searchParams.orderByNumber2 = undefined
            }
            this.fetchData(1);
        },
        getHzzt(patient) {
            const hzzt = patient.hzzt
            const hzztObj = rzConstant.hzzt.find(obj=>obj.id === hzzt)
            if (hzztObj)
                return hzztObj.name
            return hzzt
        }

    },
}
</script>

<style scoped>
.search-bar-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    justify-content: space-between;
}

.area-operate {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.area-operate img {
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.button-wrapper {
    display: flex;
}
.search-sort-wrapper {
    display: flex;
    align-items: center;
}
.search-input-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 5px;
}

.search-bar {
    width: 300px;
    margin-left: 5px;
    height: 30px;
}

.search-input {
    background: #FFFFFF;
    border-radius: 8px 0 0 8px;
    height: 100%;
    padding-left: 5px;
}

.search-input >>> .el-input__inner {
    height: 30px;
    border: unset;
}

.search-button {
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.search-button >>> .el-image {
    width: 30px;
    height: 30px;
}

.rzrq-header {
    width: 200px;
}

.rzrq-header >>> .el-range-input {
    font-size: 10px;
}

.select-header >>> .el-input__inner {
    font-size: 10px;
}

</style>
<style>
.xh-confirm-button {
    background: #556BE5;
    border-color: #556BE5;
}
.xh-confirm-button:hover {
    background: rgb(85, 107, 229, 0.8);
    border-color: #556BE5;
}
.xh-cancel-button:hover {
    background: rgb(85, 107, 229, 0.2);
    border-color: rgb(85, 107, 229, 0.2);
    color: #556BE5;
}
</style>