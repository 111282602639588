<template>
    <div class="sb-container">
        <el-button class="sb-btn" :class="orderProperty==='surveyModifiedTime'?'sb-btn-selected':''" @click="doClick('surveyModifiedTime')">编辑时间</el-button>
        <el-button class="sb-btn" :class="orderProperty==='lastSfsj'?'sb-btn-selected':''" @click="doClick('lastSfsj')">随访时间</el-button>
        <el-button class="sb-btn" :class="orderProperty==='numberForSort'?'sb-btn-selected':''" @click="doClick('numberForSort')">入组时间</el-button>
    </div>
</template>

<script>
export default {
    name: "nh-sort-button",
    model: {
        prop: 'orderProperty',
        event: 'change'
    },
    props:{
        orderProperty: String
    },
    data() {
        return {

        }
    },
    methods:{
        doClick(orderProperty) {
            this.$emit('change', orderProperty)
        }
    }
}
</script>

<style scoped>
.sb-container {
    display: flex;
}
.sb-btn {
    background: #FFFFFF;
    border-radius: 8px;
    width: 92px;
    height: 30px;
    font-family: PingFangSC-Regular;
    font-size: 15px;
    color: #1B243F;
    letter-spacing: 0;
    line-height: unset;
    padding: unset;
}
.sb-btn-selected {
    background: #556BE5;
    font-size: 14px;
    color: #FFFFFF;
}
</style>